import * as zip from "@zip.js/zip.js";
import { readNcxFile } from './NcxReader.js';
async function getCssFilesContent(entries) {
    const cssFileContents = [];
    for (const entry of entries) {
        if (entry.filename.endsWith(".css")) {
            // @ts-ignore
            cssFileContents.push(await entry.getData(new zip.TextWriter()));
        }
    }
    return cssFileContents;
}
export async function parseEpubFile(file) {
    const reader = new zip.ZipReader(new zip.BlobReader(file));
    const fileData = [];
    const entries = await reader.getEntries();
    for (const entry of entries) {
        if (entry.filename.endsWith(".ncx")) {
            // @ts-ignore
            const ncxData = await entry.getData(new zip.TextWriter());
            const files = readNcxFile(ncxData);
            for (const file of files) {
                const htmlData = await getFileFromEntries(file, entries);
                fileData.push(htmlData);
            }
        }
    }
    const cssFiles = await getCssFilesContent(entries);
    reader.close();
    return {
        htmlContent: fileData,
        cssFiles: cssFiles
    };
}
async function getFileFromEntries(file, entries) {
    for (const entry of entries) {
        if (entry.filename.endsWith(file)) {
            // @ts-ignore
            return await entry.getData(new zip.TextWriter());
        }
    }
    console.log(file);
    throw new Error("File not found");
}
