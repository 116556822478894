import { sanitize } from "dompurify";
import { getCss, getBody } from "./helpers/htmlHelper";
import { addParentSelectorToCss } from "./helpers/cssHelper";
type Props = {
    htmlString: string;
    stylesOff: boolean;
    cssOff: boolean;
    pageNumber: number;
}

function sanitizePage(page: string, stylesOff: boolean, cssOff: boolean) {
    const allowedAttributes: string[] = ["href", "id"];
    if (!stylesOff) allowedAttributes.push("style");
    if (!cssOff) allowedAttributes.push("class");
    return sanitize(page, { ALLOWED_ATTR: allowedAttributes });
    
}

export function PreprocessPage({ htmlString, stylesOff, cssOff, pageNumber }: Props) {
    const divClassName = `preprocessed-page-${pageNumber}`;
    return (
        <div key={pageNumber} className={divClassName}>
        <style>
            {addParentSelectorToCss([getCss(htmlString)], divClassName)}
        </style>
        <div dangerouslySetInnerHTML={{ __html: sanitizePage(getBody(htmlString), stylesOff, cssOff) }}></div>
        </div>
    )
}