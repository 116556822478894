import { Wrapper } from 'bookdata';
import { CssState, PreprocessBook, StyleState } from './PreprocessBook.tsx';
import { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'graphics';

type Props = {
    pages: string[];
    styles: StyleState;
    css: CssState;
    parsedPages: Wrapper[];
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
};

export function DualBookView({pages, styles, css, parsedPages, pageNumber, setPageNumber}: Props) {

    function increasePageNumber() {
        if (pageNumber + 1 >= pages.length) {
            setPageNumber(0);
        } else {
            setPageNumber(pageNumber + 1);
        }
    }

    function decreasePageNumber() {
        if (pageNumber - 1 < 0) {
            setPageNumber(pages.length - 1);
        } else {
            setPageNumber(pageNumber - 1);
        }
    }

    return (
        <div className='flex flex-row justify-center'>
            <div className='w-fit cursor-pointer h-fit' onClick={() => decreasePageNumber()}>
                <ChevronLeft width={50} height={75} />
            </div>
            <PreprocessBook css={css} style={styles} pages={[pages[pageNumber] ?? ""]} />
            <div className='flex flex-col w-2/5'>
                <div>{parsedPages[pageNumber]?.toJSXBottomFootnotes() ?? ""}</div>
            </div>
            <div className='w-fit cursor-pointer h-fit' onClick={() => increasePageNumber()}>
                <ChevronRight width={50} height={75} />
            </div>
        </div>
    );
}