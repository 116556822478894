import Alert from 'react-bootstrap/esm/Alert.js';
import CloseButton from 'react-bootstrap/esm/CloseButton.js';
import React from "react";
export function ErrorMessage({ message, setErrMsgOff }) {
    const mainContent = message.split("\n").map((paragraph, index) => { return React.createElement("p", { key: `error-msg-${index}` }, paragraph); });
    return (React.createElement("div", { className: 'w-fit' },
        React.createElement(Alert, { variant: 'danger' },
            mainContent,
            React.createElement(CloseButton, { onClick: () => setErrMsgOff() }))));
}
