import React, { useState, useEffect, useRef } from "react";
import { Rules } from './rules/Rules.tsx';
import { BookParts, Parser, Rule } from 'bookparser';
import { Wrapper } from 'bookdata';
import { getCssNodes } from './helpers/cssHelper.ts';
import Button from 'react-bootstrap/Button';
import { DualBookView } from './DualBookView.tsx';
import { View } from './ViewState.ts';
import { CssState, StyleState } from './PreprocessBook.tsx';
import { NavigationBar } from './NavigationBar.tsx';
import { ExamplesOfRule } from './ExampleView.tsx';
import { BookInputForm } from './SubmitBook.tsx';
import { MessageHandler, MessageType, UserManager } from 'bookwebsite-usermanager';
import {LoginPage, GetMessageRef, messageHandlerFromRef} from "pagecommons";

type Props = {
    pages: string[];
    css: CssState;
    styles: StyleState;
    userManager: UserManager;
    getMessageRef: React.RefObject<GetMessageRef>;
};

const parentSelector = "preparsed-book";

export function EpubViewer({pages, css, styles, userManager, getMessageRef}: Props) {
    const [rules, setRules] = useState<Rule[]>([]);
    const [parsedPages, setParsedPages] = useState<Wrapper[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(0);

    const [exampleRule, setExampleRule] = useState<Rule>(new Rule({ tag: "" }, {}, BookParts.Paragraph));
    const [view, setView] = useState<View>(View.Book);

    function getMessageHandler(): MessageHandler {
        return messageHandlerFromRef(getMessageRef.current?.getMessageRef());
    }

    function handleParsing() {
        setView(View.Book);
        const parser = new Parser(rules);
        console.log(JSON.stringify(parser));
        try {
            setParsedPages(parser.parseBook(pages));
        } catch (e) {
            console.error(e);
            let message;
            if (e instanceof Error) {
                message = e.message;
            } else {
                message = "Unknown error";
            }
            const messageHandler = getMessageHandler();
            messageHandler.createMessage(message, MessageType.Error);
        }
    }

    function MainContent() {
        if (view === View.Example) {
            return <ExamplesOfRule rule={exampleRule} pages={pages} css={css} styles={styles} />;
        } else if (view === View.Book) {
            return <DualBookView css={css} styles={styles} parsedPages={parsedPages} pages={pages} pageNumber={pageNumber} setPageNumber={setPageNumber} />;
        } else if (view === View.Styles) {
            return (
                <>
                    {Array.from(styles.styles).map((style, indx) => <p key={indx} className="w-1/2">{style}</p>)}
                </>
            )
        } else if (view === View.CSS) {
            const cssNodes = css.css.flatMap(getCssNodes).map((node) => { return node.replaceAll(`.${parentSelector} `, ""); });
            return (
                <>
                    {cssNodes.map((cssPage, indx) => <p key={indx} className="w-1/2">{cssPage}</p>)}
                </>
            );
        } else if (view === View.SubmitBook) {
            const totalChildren = parsedPages.flatMap((page) => page.getChildren());
            return (
                <BookInputForm parsedBook={new Wrapper(totalChildren)} userManager={userManager} parser={new Parser(rules)}
                />
            )
        }
    }

    return (
        <>
        <div className='flex flex-col'>
        <Rules rules={rules} setRules={setRules} pages={pages} setView={setView} setExampleRule={setExampleRule} getMessageHandler={getMessageHandler} />
        <Button className="w-fit mx-auto" onClick={handleParsing}>Parse Original Book</Button>
    </div>
    <NavigationBar setView={setView} viewConfig={{book: true, example: true, css: true, styles: true, submit: true}} />
    <MainContent />
    </>
    );
}