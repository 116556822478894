import React from 'react';
export function UpArrow({ width, height }) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: `${width}`, height: `${height}`, fill: "currentColor", viewBox: "0 0 16 16" },
        React.createElement("path", { fillRule: "evenodd", d: "M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" })));
}
export function DownArrow({ width, height }) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: `${width}`, height: `${height}`, fill: "currentColor", viewBox: "0 0 16 16" },
        React.createElement("path", { "fill-rule": "evenodd", d: "M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" })));
}
export function RightArrow({ width, height }) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: `${width}`, height: `${height}`, fill: "currentColor", viewBox: "0 0 16 16" },
        React.createElement("path", { fillRule: "evenodd", d: "M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" })));
}
export function LeftArrow({ width, height }) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: `${width}`, height: `${height}`, fill: "currentColor", viewBox: "0 0 16 16" },
        React.createElement("path", { fillRule: "evenodd", d: "M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" })));
}
export function Expand({ width, height }) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: `${width}`, height: `${height}`, fill: "currentColor", viewBox: "0 0 16 16" },
        React.createElement("path", { "fill-rule": "evenodd", d: "M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707" })));
}
export function Contract({ width, height }) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: `${width}`, height: `${height}`, fill: "currentColor", viewBox: "0 0 16 16" },
        React.createElement("path", { "fill-rule": "evenodd", d: "M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707M15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707" })));
}
export function ChevronLeft({ width, height }) {
    return (React.createElement("svg", { width: `${width}`, height: `${height}`, viewBox: "0 0 256 512", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" })));
}
export function ChevronRight({ width, height }) {
    return (React.createElement("svg", { width: `${width}`, height: `${height}`, viewBox: "0 0 256 512", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" })));
}
