import { Rule } from "bookparser";
import parse, { HTMLElement } from "node-html-parser";

export function getBody(htmlString: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const bodyElement = doc.body;

    if (!bodyElement) {
        throw new Error("Body element not found");
    }

    return bodyElement.innerHTML.trim() || "";
}

export function getCss(htmlString: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const styleElement = doc.querySelector("style");
    if (!styleElement) {
        return "";
    }

    return styleElement.innerHTML.trim() || "";
}


export function getStyleAttr(htmlString: string): string[] {
    const styleRegex = /style="([^"]*)"/g;
    const matches = htmlString.matchAll(styleRegex);
    const styles: string[] = [];
    if (matches) {
        for (const match of matches) {
            const content = match[1]; // Access captured content from group 1
            styles.push(content);
        }
    }
    return styles;
}

export function findElementsInHtml(htmlString: string, rule: Rule): HTMLElement[] {
    const node = parse(htmlString);
    const body = node.querySelector("body");
    if (!body) {
        return [];
    }
    return body.querySelectorAll("*").filter((ele) => {return rule.matchesRule(ele);});
}