import Nav from "react-bootstrap/Nav";
import { View } from "./ViewState";

type Props = {
    setView: (view: View) => void;
    viewConfig: ViewConfig;
}

interface ViewConfig {
    book?: boolean;
    example?: boolean;
    styles?: boolean;
    css?: boolean;
    submit?: boolean;
}

export function NavigationBar({setView, viewConfig}: Props) {
    const navBarChildren: JSX.Element[] = [];
    if (viewConfig.book) {
        navBarChildren.push(<Nav.Item>
            <Nav.Link onClick={() => setView(View.Book)}>Books</Nav.Link>
        </Nav.Item>);
    }
    if (viewConfig.example) {
        navBarChildren.push(<Nav.Item>
            <Nav.Link onClick={() => setView(View.Example)}>Example</Nav.Link>
        </Nav.Item>);
    }
    if (viewConfig.styles) {
        navBarChildren.push(<Nav.Item>
            <Nav.Link onClick={() => setView(View.Styles)}>Styles</Nav.Link>
        </Nav.Item>);
    }
    if (viewConfig.css) {
        navBarChildren.push(<Nav.Item>
            <Nav.Link onClick={() => setView(View.CSS)}>CSS</Nav.Link>
        </Nav.Item>);
    }
    if (viewConfig.submit) {
        navBarChildren.push(<Nav.Item>
            <Nav.Link onClick={() => setView(View.SubmitBook)}>Submit Book</Nav.Link>
        </Nav.Item>);
    }
    return (
        <Nav variant="tabs" className="justify-content-center">
        {navBarChildren}
      </Nav>
    )
}