import { useEffect } from 'react';
import Alert from 'react-bootstrap/esm/Alert.js';
import CloseButton from 'react-bootstrap/esm/CloseButton.js';
import React from "react";
export function SuccessMessage({ message, timeout, deleteMessage }) {
    useEffect(() => {
        const timer = setTimeout(() => {
            deleteMessage();
        }, timeout);
        return () => clearTimeout(timer);
    }, []);
    const mainContent = message.split("\n").map((paragraph, index) => { return React.createElement("p", { key: `error-msg-${index}` }, paragraph); });
    return (React.createElement("div", { className: 'w-fit' },
        React.createElement(Alert, { variant: 'success' },
            mainContent,
            React.createElement(CloseButton, { onClick: () => deleteMessage() }))));
}
