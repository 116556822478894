import { Wrapper, fromHtmlString } from "bookdata";
import React from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { UserManager } from "bookwebsite-usermanager";
import { Parser } from "bookparser";

interface BookInputProps {
    userManager: UserManager;
    parsedBook: Wrapper;
    parser: Parser;
}

export function BookInputForm({userManager, parsedBook, parser}: BookInputProps) {
    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [translator, setTranslator] = useState("");

    const handleSubmit = (event: any) => {
        event.preventDefault();
        userManager.putNewBook(title, author, parsedBook, translator, JSON.stringify(parser)).then(() => {
            setTitle("");
            setAuthor("");
            setTranslator("");
        });
      };

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the title of the book"
            onChange={(event) => setTitle(event.target.value)}
          />
        </Form.Group>
  
        <Form.Group controlId="author">
          <Form.Label>Author</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the author of the book"
            onChange={(event) => setAuthor(event.target.value)}
          />
        </Form.Group>
  
        <Form.Group controlId="translator">
          <Form.Label>Translator (optional)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the translator of the book (if any)"
            onChange={(event) => setTranslator(event.target.value)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      );
    }