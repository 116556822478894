import { HTMLElement, parse } from 'node-html-parser';
export function readNcxFile(ncxContent) {
    const htmlRoot = parse(ncxContent, { parseNoneClosedTags: false });
    const ncx = getNcx(htmlRoot);
    const map = getMap(ncx);
    const parsedNavPoints = [];
    for (const navPoint of getNavPoints(map)) {
        parsedNavPoints.push(...readNavPoint(navPoint));
    }
    parsedNavPoints.sort((a, b) => {
        return a.order - b.order;
    });
    return parsedNavPoints.map((val) => val.file);
}
function getNcx(root) {
    for (const child of root.childNodes) {
        if (child instanceof HTMLElement && child.rawTagName === "ncx") {
            return child;
        }
    }
    throw new Error("No map found");
}
function getMap(root) {
    for (const child of root.childNodes) {
        if (child instanceof HTMLElement && child.rawTagName === "navMap") {
            return child;
        }
    }
    throw new Error("No map found");
}
function* getNavPoints(root) {
    for (const child of root.childNodes) {
        if (child instanceof HTMLElement && child.rawTagName === "navPoint") {
            yield child;
        }
    }
}
function readNavPoint(node) {
    if (!(node instanceof HTMLElement && node.rawTagName === "navPoint")) {
        throw new Error("Node not nav point.");
    }
    const file = getContentSrcFromNavPoint(node);
    const order = node.getAttribute("playOrder");
    if (order === undefined)
        throw new Error("Order not found.");
    const orderToInt = parseInt(order);
    const childNavPoints = [...getNavPoints(node)].flatMap(readNavPoint);
    return [...childNavPoints, {
            file,
            order: orderToInt
        }];
}
function getContentSrcFromNavPoint(node) {
    for (const child of node.childNodes) {
        if (child instanceof HTMLElement && child.rawTagName === "content") {
            const src = child.getAttribute("src");
            if (src === undefined)
                throw new Error("No src found on content");
            return src;
        }
    }
    throw new Error("No content found.");
}
