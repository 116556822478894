import globToRegExp from 'glob-to-regexp';
export var BookParts;
(function (BookParts) {
    BookParts[BookParts["Part"] = 0] = "Part";
    BookParts[BookParts["Chapter"] = 1] = "Chapter";
    BookParts[BookParts["Section"] = 2] = "Section";
    BookParts[BookParts["Subsection"] = 3] = "Subsection";
    BookParts[BookParts["Footnote"] = 4] = "Footnote";
    BookParts[BookParts["Paragraph"] = 5] = "Paragraph";
    BookParts[BookParts["Quote"] = 6] = "Quote";
    BookParts[BookParts["None"] = 7] = "None";
    BookParts[BookParts["PassThrough"] = 8] = "PassThrough";
})(BookParts || (BookParts = {}));
export var Relationship;
(function (Relationship) {
    Relationship[Relationship["NextSibling"] = 0] = "NextSibling";
    Relationship[Relationship["PriorSibling"] = 1] = "PriorSibling";
    Relationship[Relationship["Parent"] = 2] = "Parent";
    Relationship[Relationship["FirstChild"] = 3] = "FirstChild";
    Relationship[Relationship["LastChild"] = 4] = "LastChild";
    Relationship[Relationship["ChildrenMinusFirst"] = 5] = "ChildrenMinusFirst";
    Relationship[Relationship["ChildrenMinusLast"] = 6] = "ChildrenMinusLast";
})(Relationship || (Relationship = {}));
export class Rule {
    constructor(htmlRule, newContext, part, contentSelector) {
        this.htmlRule = htmlRule;
        this.newContext = newContext;
        this.part = part;
        this.contentSelector = contentSelector;
    }
    matchesRule(htmlElement) {
        if (htmlElement.rawTagName === this.htmlRule.tag) {
            const attributes = this.htmlRule.attributes ?? {};
            if (Object.keys(attributes).length !== Object.keys(htmlElement.attributes).length)
                return false;
            for (const [key, value] of Object.entries(attributes)) {
                const tagValue = htmlElement.getAttribute(key);
                if (tagValue === undefined)
                    return false;
                const regex = globToRegExp(value);
                if (!regex.test(tagValue) && value !== tagValue)
                    return false;
            }
            return true;
        }
        return false;
    }
    applyRelationship(htmlElement, relationship) {
        switch (relationship) {
            case Relationship.NextSibling:
                if (htmlElement.nextSibling === null)
                    return [];
                return [htmlElement.nextSibling];
            case Relationship.PriorSibling:
                if (htmlElement.previousSibling === null)
                    return [];
                return [htmlElement.previousSibling];
            case Relationship.Parent:
                return [htmlElement.parentNode];
            case Relationship.FirstChild:
                if (htmlElement.firstChild === undefined)
                    return [];
                return [htmlElement.firstChild];
            case Relationship.LastChild:
                if (htmlElement.lastChild === undefined)
                    return [];
                return [htmlElement.lastChild];
            case Relationship.ChildrenMinusFirst:
                return htmlElement.childNodes.slice(1);
            case Relationship.ChildrenMinusLast:
                return htmlElement.childNodes.slice(0, -1);
        }
    }
    toJSON() {
        return {
            htmlRule: this.htmlRule,
            newContext: this.newContext,
            // How to serialize enum?
            part: this.part,
            contentRule: this.contentSelector
        };
    }
    static fromJSON(json) {
        return new Rule(json.htmlRule, json.newContext, json.part, json.contentRule);
    }
}
