import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { BookParts, Relationship, Rule, Context } from "bookparser";
import { MessageHandler, MessageType } from "bookwebsite-usermanager";

type Props = {
    id: number;
    deleteCallback: (id: number) => void;
    getExamplesCallback: (id: number) => void;
    getMessageHandler: () => MessageHandler;
    setParentRule: (rule: Rule) => void;
    defaultRule?: Rule;
};

type RelationshipSelectProps = {
    defaultRelationships: Relationship[];
    id: number;
    setParentRelationships: (relationships: Relationship[]) => void;
}
const relationshipVals = Object.values(Relationship).filter((val) => isNaN(Number(val)));
const bookPartVals = Object.values(BookParts).filter((val) => isNaN(Number(val)));

export function RelationshipSelect({ defaultRelationships, id, setParentRelationships }: RelationshipSelectProps) {
    const [relationships, setRelationships] = useState<Relationship[]>(defaultRelationships);

    const firstOption = "Open this select menu";
    const relationshipOptions = <><option>{firstOption}</option>
        {relationshipVals.map((val, index) =>
            <option value={val} key={`option-${index}-relationship-${index}`}>{val}</option>
        )}</>;

    function updateRelationships(val: string, index: number) {
        const newRelationships = [...relationships];
        if (!relationshipVals.includes(val)) {
            newRelationships.splice(index, 1);
        } else {
            const newVal = Relationship[val as keyof typeof Relationship];
            newRelationships[index] = newVal;
        }
        setRelationships(newRelationships);
        setParentRelationships(newRelationships);
    }

    function getRelationshipSelector(index: number, value: string) {
        return (
            <Form.Select aria-label="Default select example" key={`${id}-${index}-relationship`} value={value} onChange={(event) => { updateRelationships(event.target.value, index); }}>
                {relationshipOptions}
            </Form.Select>
        )
    }

    const relationshipSelections = relationships.map((relationship, index) => getRelationshipSelector(index, relationshipVals[relationship].toString()));

    relationshipSelections.push(getRelationshipSelector(relationships.length, firstOption));

    return (<>
        {relationshipSelections}
    </>);
}

export function RuleForm({ id, deleteCallback, getExamplesCallback, getMessageHandler, setParentRule, defaultRule }: Props) {
    const [tag, setTag] = useState(defaultRule?.htmlRule.tag ?? "");
    const [attributes, setAttribures] = useState(JSON.stringify(defaultRule?.htmlRule.attributes ?? "") ?? "");
    const [italics, setItalics] = useState(defaultRule?.newContext.italic ?? false);
    const [bold, setBold] = useState(defaultRule?.newContext.bold ?? false);
    const [bookPart, setBookPart] = useState<string>(bookPartVals[defaultRule?.part ?? 0].toString());
    const [relationships, setRelationships] = useState<Relationship[]>(defaultRule?.contentSelector?.relationships ?? []);
    const [isGotoHref, setIsGotoHref] = useState(defaultRule?.contentSelector?.gotoHref ?? false);
    const errorId = useRef<string | null>(null);

    useEffect(() => {
        try {
            JSON.parse(attributes);
            if (errorId.current) {
                console.log("Delete message: " + errorId.current);
                getMessageHandler().deleteMessage(errorId.current);
            }
        } catch (error) {
            const errorMessage = "Invalid json: " + attributes;
            if (errorId.current) {
                getMessageHandler().updateMessage(errorId.current, errorMessage, MessageType.Error);
            } else {
                errorId.current = getMessageHandler().createMessage(errorMessage, MessageType.Error);
            }
            return;
        }
        setParentRule(new Rule(
            { tag, attributes: JSON.parse(attributes) },
            { italic: italics, bold },
            BookParts[bookPart as keyof typeof BookParts],
            { gotoHref: isGotoHref, relationships }))
    }, [tag, attributes, italics, bold, bookPart, relationships, isGotoHref]);

    return (
        <div className="h-fit w-1/4 p-10 border-2 border-black border-r-2 mx-auto">
            <Form.Group className="mb-3">
                <Form.Label>Tag</Form.Label>
                <Form.Control type="text" value={tag} onChange={(event) => { setTag(event.target.value) }} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Selector attributes</Form.Label>
                <Form.Control as="textarea" rows={5} value={attributes} onChange={(event) => { setAttribures(event.target.value) }} />
            </Form.Group>
            <div className="flex flex-row space-x-1">
                <Form.Group className="mb-3">
                    <Form.Check type="checkbox" label="Italics" checked={italics} onChange={(event) => { setItalics(!italics) }} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check type="checkbox" label="Bold" checked={bold} onChange={(event) => { setBold(!bold) }} />
                </Form.Group>
            </div>
            <Form.Select aria-label="Default select example" value={bookPart} onChange={(event) => { setBookPart(event.target.value) }}>
                <option>Open this select menu</option>
                {bookPartVals.map((val, index) =>
                    <option value={val} key={`option-${index}-rule-${id}`}>{val}</option>
                )}
            </Form.Select>
            <Form.Group className="mb-3">
                <Form.Check type="checkbox" label="Go to href" checked={isGotoHref} onChange={(event) => { setIsGotoHref(!isGotoHref) }} />
            </Form.Group>
            {isGotoHref && <RelationshipSelect defaultRelationships={relationships} id={id} setParentRelationships={setRelationships} />}
            <Button onClick={() => {
                deleteCallback(id);
            }}>Delete rule</Button>
            <Button onClick={() => {
                getExamplesCallback(id);
            }}>Get examples</Button>
        </div>
    );
}