import { findElementsInHtml } from "./helpers/htmlHelper";
import { CssState, PreprocessBook, StyleState } from "./PreprocessBook";
import { Rule } from "bookparser";
import { HTMLElement } from "node-html-parser";


type Props = {
    pages: string[];
    styles: StyleState;
    css: CssState;
    rule: Rule;
};

export function ExamplesOfRule({pages, styles, css, rule}: Props) {
    const elements: HTMLElement[] = pages.flatMap((page) => {return findElementsInHtml(page, rule);});

    return (
        <div className="flex flex-row">
            <PreprocessBook style={styles} css={css} pages={elements.map((element) => element.outerHTML)} />
            <div className="flex flex-col">
                {elements.map((element, index) => <div key={index}>{element.outerHTML}</div>)}
            </div>
        </div>
    );
}