import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "graphics";

type Props = {
    children: JSX.Element[];
    elementsPerView: number;
};

export function Carousel({ children, elementsPerView }: Props) {
    const [index, setIndex] = useState(0);
    // Map list of children into list of lists of elements
    const childrenGroups: JSX.Element[][] = [];
    for (let i = 0; i < children.length; i += elementsPerView) {
        childrenGroups.push(children.slice(i, i + elementsPerView));
    }

    if (childrenGroups.length === 0) {
        return (<></>);
    }

    function decreaseIndex() {
        if (index === 0) {
            setIndex(childrenGroups.length - 1);
        } else {
            setIndex(index - 1);
        }
    }

    function increaseIndex() {
        if (index === childrenGroups.length - 1) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }

    function Indicators() {
        return (
            <div className="flex flex-row flex-wrap items-center mx-auto py-8">
                {childrenGroups.map((_, i) => (
                    <div
                        key={i}
                        className={`${i === index ? " text-blue-500 text-xl" : "text-black"} px-2 cursor-pointer hover:text-xl`}
                        onClick={() => setIndex(i)}
                    >
                        {i + 1}
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="flex flex-col" key={`${children.length}-size-carousel`}>
            <div className="flex flex-row flex-wrap items-center">
                <div className="cursor-pointer p-4" onClick={decreaseIndex}><ChevronLeft width={20} height={50} /></div>
                {childrenGroups[index]}
                <div className="cursor-pointer p-4" onClick={increaseIndex}><ChevronRight width={20} height={50} /></div>
            </div>
            <Indicators />
        </div>
    );
}