import React from "react";
import Button from "react-bootstrap/Button";
import { RuleForm } from "./RuleForm";
import { HtmlFeatures, getHtmlFeatures } from "../helpers/getAllFeatures";
import { BookParts, Context, Rule } from "bookparser";
import { Carousel } from "./Carousel";
import { View } from "../ViewState";
import { MessageHandler } from "bookwebsite-usermanager";

type RulesProps = {
    setRules: (rules: Rule[]) => void;
    setView: (view: View) => void;
    setExampleRule: (rule: Rule) => void;
    getMessageHandler: () => MessageHandler;
    pages: string[];
    rules: Rule[];
}

const DEFAULT_TAG_TO_PART: Record<string, BookParts> = {
    "div": BookParts.PassThrough,
    "span": BookParts.PassThrough,
    "p": BookParts.Paragraph,
    "i": BookParts.PassThrough,
    "em": BookParts.PassThrough,
    "b": BookParts.PassThrough,
    "strong": BookParts.PassThrough,
    "blockquote": BookParts.Quote,
    "a": BookParts.Footnote,
    "h1": BookParts.Part,
    "h2": BookParts.Part,
    "h3": BookParts.Part,
    "h4": BookParts.Part,
    "h5": BookParts.Part,
    "h6": BookParts.Part,
    "table": BookParts.PassThrough,
    "tr": BookParts.PassThrough,
    "td": BookParts.PassThrough,
    "th": BookParts.PassThrough,
    "hr": BookParts.PassThrough,
    "br": BookParts.PassThrough,
    "sup": BookParts.PassThrough
};

const DEFAULT_TAG_TO_CONTEXT: Record<string, Context> = {
    "i": {italic: true},
    "em": {italic: true},
    "b": {bold: true},
    "strong": {bold: true}
};

export function Rules({ setRules, pages, getMessageHandler, setView, setExampleRule, rules }: RulesProps) {

    function getExample(id: number) {
        const rule = rules[id];
        setView(View.Example);
        setExampleRule(rule);
    }

    function updateRule(newRule: Rule, index: number) {
        const newRules = [...rules];
        newRules[index] = newRule;
        setRules(newRules);
    }

    function deleteRule(id: number) {
        const newRules = [...rules];
        newRules.splice(id, 1);
        setRules(newRules);
    }

    function getNewRule(index: number, defaultRule?: Rule): JSX.Element {
        return <RuleForm id={index} deleteCallback={deleteRule} getMessageHandler={getMessageHandler} key={`rule-form-${index}`}
            getExamplesCallback={getExample} defaultRule={defaultRule} setParentRule={(rule) => {updateRule(rule, index)}}/>;
    }

    function handleRuleAutogeneration() {
        function featureToRule(feature: HtmlFeatures): Rule {
            const attr: Record<string, string> = feature.otherAttributes;
                if (feature.style) {
                    attr["style"] = feature.style;
                }
                if (feature.class) {
                    attr["class"] = feature.class;
                }
                return new Rule(
                    {
                        tag: feature.tag,
                        attributes: attr
                    },
                    DEFAULT_TAG_TO_CONTEXT[feature.tag] ?? {},
                    DEFAULT_TAG_TO_PART[feature.tag] ?? BookParts.Paragraph);
        }
        
        const features = getHtmlFeatures(pages);
        const newRules: Rule[] = [];
        features.forEach((feature) => {
            newRules.push(featureToRule(feature));
        });
        setRules(newRules);
    }

    function createNewRule() {
        setRules([...rules, new Rule({tag: ""}, {}, BookParts.Paragraph)]);
    }

    return (
        <div className="flex flex-col">
            <Carousel children={rules.map((rule, indx) => getNewRule(indx, rule))} elementsPerView={3} />
            <Button className="w-fit mx-auto" onClick={createNewRule}>Add new rule</Button>
            <Button className="w-fit mx-auto" onClick={handleRuleAutogeneration}>Autogenerate Rules</Button>
        </div>
    )
}