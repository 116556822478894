import React, { useState } from "react";
import { Parser } from 'bookparser';
import { Wrapper, fromHtmlString } from 'bookdata';
import { DualBookView } from './DualBookView.tsx';
import { View } from './ViewState.ts';
import { NavigationBar } from './NavigationBar.tsx';
import { BookInputForm } from './SubmitBook.tsx';
import { UserManager } from 'bookwebsite-usermanager';

type Props = {
    pages: string[];
    userManager: UserManager;
};

export function XmlViewer({pages, userManager}: Props) {
    const [pageNumber, setPageNumber] = useState<number>(0);

    const [view, setView] = useState<View>(View.Book);

    function MainContent() {
        if (view === View.Book) {
            const book = new Wrapper(pages.flatMap((page) => fromHtmlString(page).getChildren()));
            return <DualBookView css={{"css": [], "cssOff": true}} styles={{styles: new Set(), stylesOff: true}} parsedPages={[book]} pages={[]} pageNumber={pageNumber} setPageNumber={setPageNumber} />;
        } else if (view === View.SubmitBook) {
            const book = new Wrapper(pages.flatMap((page) => fromHtmlString(page).getChildren()));
            return (
                <BookInputForm parsedBook={book} userManager={userManager} parser={new Parser()} />
            )
        }
    }

    return (
        <>
    <NavigationBar setView={setView} viewConfig={{book: true, submit: true}}/>
    <MainContent />
    </>
    );
}