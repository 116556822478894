import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import React from "react";
import Spinner from "react-bootstrap/esm/Spinner.js";
import { Messages } from "./Messages.js";
export const LoginPage = forwardRef(function LoginPage(props, ref) {
    const [loggedIn, setLoggedIn] = useState(false);
    const messageRef = useRef(null);
    const queryString = props.queryString;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    const userManager = props.userManager;
    const mainPage = props.mainPage;
    useEffect(() => {
        if (userManager.isLoggedIn()) {
            setLoggedIn(true);
            return;
        }
        if (code === null) {
            props.setUrl(userManager.getLoginUrl());
            return;
        }
        userManager.loginUser(code).then(() => {
            console.log("Finished sign in request, logged in is: " + userManager.isLoggedIn());
            if (!userManager.isLoggedIn()) {
                props.setUrl(userManager.getLoginUrl());
                return;
            }
            setLoggedIn(true);
        });
    }, []);
    // Heartbeat every 5 minutes
    useEffect(() => {
        const interval = setInterval(() => {
            if (userManager.isLoggedIn()) {
                userManager.heartbeat();
            }
        }, 1000 * 60 * 5);
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        userManager.setMessageHandler({
            createMessage(message, messageType) {
                if (messageRef.current === null) {
                    throw new Error("Message ref is null");
                }
                return messageRef.current.createMessage(message, messageType);
            },
            deleteMessage(id) {
                if (messageRef.current === null) {
                    throw new Error("Message ref is null");
                }
                return messageRef.current.deleteMessage(id);
            },
            updateMessage(id, message, messageType) {
                if (messageRef.current === null) {
                    throw new Error("Message ref is null");
                }
                return messageRef.current.updateMessage(id, message, messageType);
            }
        });
    }, []);
    useImperativeHandle(ref, () => ({
        getMessageRef: () => messageRef
    }), [messageRef]);
    if (!loggedIn) {
        return (React.createElement("div", { className: "flex items-center justify-center h-screen w-screen" },
            React.createElement(Spinner, { animation: "border", role: "status", className: "mx-auto my-auto" },
                React.createElement("span", { className: "visually-hidden" }, "Loading..."))));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement(Messages, { ref: messageRef }),
            mainPage));
    }
});
export function messageHandlerFromRef(ref) {
    if (ref === undefined || ref.current === null) {
        throw new Error("No message handler found");
    }
    return ref.current;
}
