import { forwardRef, useImperativeHandle, useState } from "react";
import { ErrorMessage } from "./ErrorMessage.js";
import { SuccessMessage } from "./SuccessMessage.js";
import { LoadingMessage } from "./LoadingMessage.js";
import { MessageType } from "bookwebsite-usermanager";
import React from "react";
export const Messages = forwardRef(function Messages(props, ref) {
    const [messages, setMessages] = useState([]);
    function deleteMessage(id) {
        setMessages(messages.filter((message) => message.id !== id));
    }
    useImperativeHandle(ref, () => {
        return {
            createMessage(message, messageType) {
                const id = crypto.randomUUID();
                setMessages([...messages, { message, messageType, id }]);
                return id;
            },
            deleteMessage(id) { deleteMessage(id); },
            updateMessage(id, message, messageType) {
                const newMessages = messages.filter((messageItem) => messageItem.id !== id);
                setMessages([{ id, message, messageType }, ...newMessages]);
            }
        };
    }, [messages]);
    const messageElements = [];
    for (const message of messages) {
        if (message.messageType === MessageType.Error) {
            messageElements.push(React.createElement(ErrorMessage, { message: message.message, setErrMsgOff: () => deleteMessage(message.id) }));
        }
        else if (message.messageType === MessageType.Success) {
            messageElements.push(React.createElement(SuccessMessage, { timeout: 5000, message: message.message, deleteMessage: () => deleteMessage(message.id) }));
        }
        else if (message.messageType === MessageType.Loading) {
            messageElements.push(React.createElement(LoadingMessage, { message: message.message }));
        }
    }
    return (React.createElement("div", { className: "fixed top-3 left-0 right-0 mx-auto flex flex-col items-center justify-center gap-2 w-fit" }, messageElements));
});
