import { Chapter, Footnote, Paragraph, Part, Quote, Section, Subsection, Wrapper, Words, fromHtmlString } from 'bookdata';
import { BookParts, Rule } from "./Rule.js";
import { HTMLElement, TextNode, parse } from "node-html-parser";
export class Parser {
    constructor(rules = []) {
        this.rules = [];
        this.errMsgs = [];
        this.rules.push(...rules);
    }
    addRules(rules) {
        this.rules.push(...rules);
    }
    parseBook(pages) {
        this.errMsgs = [];
        const book = pages.map((page) => { return this.parsePage(page); });
        if (this.errMsgs.length > 0) {
            throw new Error(this.errMsgs.join("\n\n"));
        }
        console.log(book);
        const cleanedBooks = book.map((page) => {
            const cleanBook = page.clean();
            const reserializedBook = fromHtmlString(cleanBook.toString());
            return reserializedBook;
        });
        return cleanedBooks;
    }
    parsePage(page) {
        const root = parse(page);
        const body = root.getElementsByTagName("body")[0];
        return new Wrapper(this.parseNodes(body.childNodes, {}, root));
    }
    parseNodes(nodes, context, root) {
        const bookParts = [];
        for (const node of nodes) {
            bookParts.push(...this.parseNode(node, context, root));
        }
        return bookParts;
    }
    parseNode(node, context, root) {
        if (node instanceof TextNode) {
            return [new Words(node.innerText, context.italic ?? false, context.bold ?? false)];
        }
        else if (node instanceof HTMLElement) {
            for (const rule of this.rules) {
                if (rule.matchesRule(node)) {
                    return this.applyRule(node, rule, context, root);
                }
            }
            this.errMsgs.push("No rule matched the following tag: " + node.rawTagName + " and attributes:" + JSON.stringify(node.attributes));
            return [];
        }
        else {
            console.log(node);
            throw new Error("Unknown type of node.");
        }
    }
    applyRule(htmlElement, rule, context, root) {
        const innerText = htmlElement.innerText;
        const newContext = { ...context, ...rule.newContext };
        switch (rule.part) {
            case (BookParts.Part):
                return [new Part(innerText)];
            case (BookParts.Chapter):
                return [new Chapter(innerText)];
            case (BookParts.Section):
                return [new Section(innerText)];
            case (BookParts.Subsection):
                return [new Subsection(innerText)];
            case (BookParts.None):
                return [];
        }
        const insideContent = this.getContent(htmlElement, rule, newContext, root);
        switch (rule.part) {
            case (BookParts.Paragraph):
                return [new Paragraph(insideContent)];
            case (BookParts.Footnote):
                return [new Footnote(insideContent)];
            case (BookParts.Quote):
                return [new Quote(insideContent)];
            case (BookParts.PassThrough):
                return insideContent;
        }
    }
    getContent(htmlElement, rule, context, root) {
        if (rule.contentSelector === undefined || (!rule.contentSelector.gotoHref && rule.contentSelector.relationships.length === 0)) {
            return this.parseNodes(htmlElement.childNodes, context, root);
        }
        else {
            let element = htmlElement;
            if (rule.contentSelector.gotoHref) {
                const newElement = root.getElementById(element.getAttribute("href")?.replace("#", "") ?? "");
                if (!(newElement instanceof HTMLElement))
                    throw new Error("Unexpected state reached: HTML element expected to be found");
                element = newElement;
            }
            let nodes = [element];
            for (const relationship of rule.contentSelector.relationships ?? []) {
                nodes = nodes.flatMap((node) => {
                    if (!(node instanceof HTMLElement))
                        throw new Error("Unexpected state reached: Non HTML element found in applying relationships");
                    return rule.applyRelationship(node, relationship);
                });
            }
            const parsedNodes = this.parseNodes(nodes, context, root);
            return parsedNodes;
        }
    }
    toJSON() {
        return {
            rules: this.rules.map((rule) => rule.toJSON()),
            type: "parser"
        };
    }
    static fromJSON(json) {
        const parser = new Parser();
        parser.addRules(json["rules"].map((rule) => Rule.fromJSON(rule)));
        return parser;
    }
}
