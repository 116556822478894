import './App.css';
import React, { useState, useEffect, useRef } from "react";
import Form from 'react-bootstrap/Form';
import { parseEpubFile } from "bookparser";
import { getStyleAttr } from './helpers/htmlHelper.ts';
import { addParentSelectorToCss } from './helpers/cssHelper.ts';
import { CssState, StyleState } from './PreprocessBook.tsx';
import { UserManager } from 'bookwebsite-usermanager';
import {LoginPage, GetMessageRef} from "pagecommons";
import { EpubViewer } from './EpubViewer.tsx';
import { XmlViewer } from './XmlViewer.tsx';

const userManager = new UserManager(window.location.origin);
const parentSelector = "preparsed-book";
const EPUB_FILE_TYPE = "application/epub+zip";
const XML_FILE_TYPE = "text/xml";
function App() {
    const [pages, setPages] = useState<string[]>([]);
    const [css, setCss] = useState<CssState>({ css: [], cssOff: true });
    const [styles, setStyles] = useState<StyleState>({ styles: new Set<string>(), stylesOff: true });
    const [file, setFile] = useState<File>();
    const [fileType, setFileType] = useState("");

    const ref = useRef<GetMessageRef>(null);

    async function parseZipFile(file: Blob) {
        const epubFile = await parseEpubFile(file);
        const allStyles = new Set<string>();
        epubFile.htmlContent.flatMap(getStyleAttr).forEach((style) => allStyles.add(style));
        const updatedCssFiles = addParentSelectorToCss(epubFile.cssFiles, parentSelector);
        setCss({ css: updatedCssFiles, cssOff: css.cssOff });
        setStyles({ styles: allStyles, stylesOff: styles.stylesOff });
        setPages(epubFile.htmlContent);
    }

    async function parseFileAsXml(file: Blob) {
        const xml = await file.text();
        setCss({ css: [], cssOff: true });
        setStyles({ styles: new Set(), stylesOff: true });
        setPages([xml]);
    }

    useEffect(() => {
        console.log(fileType);
        if (fileType === EPUB_FILE_TYPE && file) {
            parseZipFile(file);
        } else if (fileType === XML_FILE_TYPE && file) {
            parseFileAsXml(file);
        }
    }, [file]);

    function InputForm() {
        return (
            <>
                <div>
                    Submit file
                </div>
                <Form.Group controlId="file" className='w-fit'>
                    <Form.Label>Book file</Form.Label>
                    <Form.Control
                        type="file"
                        placeholder="Upload the book file"
                        onChange={(event) => {
                            // @ts-ignore
                            setFile(event.target.files[0]); 
                            // @ts-ignore
                            setFileType(event.target.files[0].type)}}
                    />
                </Form.Group>
                <Form.Group controlId='css'>
                    <Form.Label>CSS</Form.Label>
                    <Form.Check
                        type="checkbox"
                        label="Disable CSS"
                        checked={css.cssOff}
                        onChange={(event) => setCss({ css: css.css, cssOff: event.target.checked })}
                    />
                </Form.Group>
                <Form.Group controlId='styles'>
                    <Form.Label>Styles</Form.Label>
                    <Form.Check
                        type="checkbox"
                        label="Disable styles"
                        checked={styles.stylesOff}
                        onChange={(event) => setStyles({ styles: styles.styles, stylesOff: event.target.checked })}
                    />
                </Form.Group>
            </>
        );
    }

    function Viewer() {
        if (fileType === EPUB_FILE_TYPE) {
            return (
                <EpubViewer pages={pages} css={css} styles={styles} userManager={userManager} getMessageRef={ref} />
            );
        } else if (fileType === XML_FILE_TYPE) {
            return (
                <XmlViewer pages={pages} userManager={userManager}/>
            );
        } else {
            return (<></>);
        }
    }
    

    const app = (<>
        <div className='flex flex-col'>
            <InputForm />
            <Viewer />
        </div>
    </>);

    return (
        <LoginPage mainPage={app} userManager={userManager} ref={ref}
            queryString={window.location.search}
            setUrl={(url: string) => window.location.replace(url)} />
    );
}

export default App;
