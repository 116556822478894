import { CssNode, generate, parse as parseCss, walk } from "css-tree";

// Adds selector to the css files where css rules will now only apply
// to children of the given parent class
export function addParentSelectorToCss(cssFiles: string[], parentClass: string): string[] {
  const updatedCssFiles: string[] = [];
  for (const file of cssFiles) {
    const ast = parseCss(file);
    walk(ast, (node) => {
      if (node.type === 'Selector') {
        const newClassSelector = {
          type: "ClassSelector",
          name: parentClass
        } as CssNode;
        const combinator = {
          type: "Combinator",
          name: " "
        } as CssNode;
        node.children.unshift(combinator);
        node.children.unshift(newClassSelector);
      }
    });
    updatedCssFiles.push(generate(ast));
  }
  return updatedCssFiles;
}

export function getCssNodes(cssFile: string): string[] {
  const ast = parseCss(cssFile);
  const nodes: string[] = [];
  walk(ast, (node) => {
    if (node.type === 'StyleSheet') {
      for (const rule of node.children) {
        nodes.push(generate(rule));
      }
    }
  });
  return nodes;
}