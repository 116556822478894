import { PreprocessPage } from "./PreprocessPage";

type Props = {
    pages: string[];
    style: StyleState;
    css: CssState;
}

export interface CssState {
    css: string[];
    cssOff: boolean;
}

export interface StyleState {
    styles: Set<string>;
    stylesOff: boolean;
}

export function PreprocessBook({ pages, css, style }: Props) {
    return (<>
        <style>
            {css.cssOff ? "" : css.css}
        </style>
        <div className='flex flex-col preparsed-book w-2/5'>
            {pages.map((val, indx) => <PreprocessPage htmlString={val} stylesOff={style.stylesOff} cssOff={css.cssOff} key={indx} pageNumber={indx} />)}
        </div>
    </>
    )
}